:root {
  /* Primary Colors */
  --color-primary-dark-blue: #2B3A67;
  --color-primary-white: #FFFFFF;

  /* Secondary Colors */
  --color-secondary-light-gray: #F5F5F5;
  --color-secondary-gray: #CCCCCC;
  --color-secondary-dark-gray: #555555;

  /* Accent Colors */
  --color-accent-red: #D9534F;
  --color-accent-blue: #0275D8;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, button{
  cursor:pointer;
}
