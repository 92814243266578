.articleBox{
    max-width: 275px;
    box-sizing: border-box;
    min-width: 250px;
    margin:15px;
    padding:30px 15px;
    border: 1px solid black;    
    background-color: var(--color-primary-white);
    font-family: anton;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1px;
}

#adTitle{
    font-family: roboto;
    color:#22BF90;
}

.articleBox h2{
    color:rgb(2, 43, 58);
    font-weight: 300;
}

.articleBox img{
    max-width: 200px;
}


.articleBox img:nth-of-type(1){
    min-height: 40px;
}

.articleBox img:nth-of-type(2){
    min-height: 35px;
}

#gifWrapper{
    margin: 25px 0 15px;
    min-height: 112px;
}


#productSpecs{
    display: flex;
  flex-direction: column;
  margin: 20px 0;
  font-family: prompt;
}

#productSpecs img{
    width: 40px;
    min-height: 38px;
}

.productSpec{
    margin:5px;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
}

.specContent{
    display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
  width: 150px;
}

.specLightText{
   color: rgba(18, 18, 18, 0.75);
}

.articleBox button{   
    background-color: rgb(255, 159, 28);
    color: black;
    border-radius: 5px;
    padding: 15px;
    border: 0px;
    width: 100%;
    max-width: 200px;
    font-family: anton;
    font-size: 1.1rem;
    letter-spacing: 0.3px;
    transition: 0.20s ease-in;
}

.btn-shop:hover{
    -webkit-box-shadow: 0px 0px 0px 1.3px rgba(255,159,28,1);
    -moz-box-shadow: 0px 0px 0px 1.3px rgba(255,159,28,1);
    box-shadow: 0px 0px 0px 1.3px rgba(255,159,28,1);
}

.reviewSpan{
    font-family: prompt;
  text-transform: uppercase;
  letter-spacing: .13rem;
  font-weight: 400;
  margin: 30px 0  0;
  font-size: 0.7rem;
}