#pageWrapper{
    padding:15px;
    overflow-x: hidden;
}

#contentRow{
    display: flex;
    justify-content: center;
}


.hide{
    visibility: hidden;
    z-index: -2;
    position: absolute;
    overflow: hidden;
}

.hideBasic{
    visibility: hidden;
}

#popupWrapper{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000042;
    left: 0;
    display: flex;
    justify-content: center;  
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 10;
}


#contentWrapper{
    text-align: center;
}


.closeButton{
    position: fixed;
    right: 15px;
    top: 15px;
    border: none;
    background-color: #000000b5;
    color: white;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
}

#popupWrapper article{
    overflow: visible;
}

#popupWrapper .articleBox{
    width: 80vw;
    max-width: 80vw;
    border: none;
  box-sizing: border-box;
  margin: 25px 0;
    -webkit-box-shadow: 2px 2px 16px -4px rgba(0,0,0,1);
    -moz-box-shadow: 2px 2px 16px -4px rgba(0,0,0,1);
    box-shadow: 2px 2px 16px -4px rgba(0,0,0,1);
}


@media (max-width: 768px) {
    #contentColumn {
      width:100%;
    }
    .contentSection{        
       margin-bottom: 30px;
    }

    .contentIntro{
        margin-bottom: 25px;
    }

    #contentIntroImageWrapper{
        min-height: 51vw;
    }

    .contentWrapper{
        max-width: 100%;
    }

    .homeImageWrapper{
        width: 100%;      
        flex-shrink: 0;
      flex-grow: 0;
      min-height: 90vw;
    }

    .homeImageWrapper img{
        width: 100%;
    }

    .contentSection h2{
        margin:30px 0 20px;
    }
    #articleColumn {
        width:0%;
        visibility: hidden;
        position: absolute;
        overflow: hidden;
      }
    .btn-spacer{
        height: 5px;
    }

    .finalSection{
        margin-bottom: 40px;
    }
  }

@media (min-width: 768px) {
    #contentColumn {
      width:75%;
      max-width: 680px;
      margin-right: 25px;
    }    

    #titleWrapper{
        margin:15px 0;
    }

    .contentIntro{
        margin-bottom: 60px;
    }

    .contentIntro img{
        min-height: 380px;
    }

    .contentSection{      
        margin-bottom: 20px;        
        display: flex;
        min-height: 300px;
    }

    .homeImageWrapper{
        width: 300px!important;
        height: 300px;
        flex-shrink: 0;
      flex-grow: 0;
    }

    .reverse{
        flex-direction: row-reverse;
    }
    .reverse .contentText{
        margin-left: 0;
        margin-right: 25px;
    }

    .contentText{
        display: flex;
        flex-direction: column;
        margin-left: 25px;
    }

    .contentSection h2{
        margin: 0 0 5px;
    }
    
    .contentSection img{
        max-width: 300px; 
    }
   
    
    #articleColumn {
        width:25%;
        max-width: 275px;
      }

    .sticky{
        text-align: center;
    }
    .btn-spacer{
        height: 15px;
    }
  }

 

.btn-green{
    position: relative;
    display: inline-block;
    padding: 15px;
    border: none;
    border-radius: 10px;
    background-color: #22BF90;
    color: var(--color-primary-white);
    font-family: roboto;
    font-weight: bolder;
    font-size: 1rem;
    height: 70px;
}


#titleWrapper{
    display: flex;
    flex-direction: column;
    font-family: roboto;
}

#titleWrapper h1{
    font-size: 1.8rem;
    margin: 0;
}

#titleWrapper h1 .emojiWrapper {
    position: relative;
  width: 40px;
  height: 1.7rem;
  display: inline-block;
}

#titleWrapper h1 .emoji {
    position: absolute;
    bottom: -3px;
}

#author{
    font-weight: bold;
    font-size:0.8rem;
    margin:10px 0;
    letter-spacing: -0.35px;
}

.contentIntro{
    text-align: start;
    font-family: roboto;  
}

.contentIntro img{
    max-width: 100%;
}

.contentSection{
  font-family: roboto;      
  text-align: start;  
  width: 100%;
  font-size: 1.05rem;
}

.contentSection h2{
    font-size: 1.5rem;
}

.contentSection p{
    margin: 10px 0;
    font-size: 1rem;
}

.strongText{
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: rgb(15, 14, 14);
}

.regularText{
  letter-spacing: 0.5px;
  line-height: 1.4;
  color:black;
  font-weight: 390;
}

.finalSection{
    margin-top: 50px;
}

.finalSection p{
    background-color: rgb(243, 242, 242);
    padding: 25px;
}