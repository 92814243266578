nav{
    background-color: var(--color-primary-dark-blue);
    color:var(--color-primary-white);
    display: flex;
}


nav h1{
   
    margin: 0;
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

@media (max-width: 768px) {
    nav{        
    justify-content: start;
    }
    nav h1{
        padding: 20px 15px;
        font-size: 1.4rem;
    }
  }

@media (min-width: 768px) {
    nav{
        
    justify-content: center;
    }
    nav h1{
        padding: 20px 0;
        font-size: 3rem;
        max-width: 680px;
        width: 75%;
        margin-right: 25px;
    }

    nav div{
        width: 25%;
        max-width: 275px;
    }
  }